@import url('https://fonts.googleapis.com/css2?family=Catamaran&family=Montserrat:wght@100;400;900&family=Raleway&family=Rubik&family=Ubuntu&display=swap');

html,body,#root,.app{
  height: 100%;
  width: 100%;
}

.project-card {
  border: 1px solid rgb(25, 167, 12);
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.project-top img {
  max-width: 100%;
  max-height: 50vh;
  object-fit: contain;
  margin: 10px 0;
  min-height:150px;
}

.project-bottom {
  background-color: rgb(89, 196, 171); /* Blue background */
  color: #fff; /* White text color */
  padding: 20px;
  position: relative;
}

.project-bottom h3 {
  font-size: 1.8rem; /* Bigger font size for project name */
  margin: 0 0 10px;
}

.project-bottom .tech-stack,
.project-bottom .description,
.project-info {
  margin: 10px 0;
}

.date {
  /* Adjust this for desired position */
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.pl-12 {
  padding-left: 3rem;
}

.w-full {
  width: 100%;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.flex-row {
  flex-direction: row;
}

.flex {
  display: flex;
}

*, ::before, ::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.flex {
  display: flex;
}

.w-1\/2 {
  width: 50%;
}

.p-3 {
  padding: 0.75rem;
  background: white;
  color: black;
  font-size: large;
  font-weight: 500;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.cursor-pointer {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: inherit;
}

ol, ul, menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.h-screen {
  height: 100vh;
}

.w-full {
  width: 100%;
}

.h-\[85vh\] {
  height: 200vh;
}

.w-full {
  width: 100%;
  background: white;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  background: linear-gradient(rgb(201, 221, 222),rgb(198, 223, 224));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* button{
  color: blue;
  background-color: white;
}


button:hover {
color:lightblue;
} */

.header-button-logout button{
  border-radius: 5px;
}

.color-black{
  color: black;
}

.color-red{
  color: red;
}



/* CSS */
button {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  /* min-height: 60px; */
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 60%;
  will-change: transform;
}

button:disabled {
  pointer-events: none;
}

button:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

button:active {
  box-shadow: none;
  transform: translateY(0);
}

/* Your existing Tailwind CSS class */
.bg-red-500 {
  background-color: #e3342f;
}

.text-white {
  color: #ffffff;
}

.p-2 {
  padding: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

/* Merged styles with your provided button class */
.bg-red-500.text-white.p-2.rounded-full {
  appearance: none;
  background-color: transparent;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 60%;
  will-change: transform;
}

.bg-red-500.text-white.p-2.rounded-full:disabled {
  pointer-events: none;
}

.bg-red-500.text-white.p-2.rounded-full:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.bg-red-500.text-white.p-2.rounded-full:active {
  box-shadow: none;
  transform: translateY(0);
}


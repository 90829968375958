.header-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: white; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
  z-index: 100; 
  color: whitesmoke;
  }
  
  .logo img {
    max-width: 100%;
    max-height: 46px; /* Adjust the max height as needed */
    border-radius: 5px;
  }
  
  .header-button {
    display: flex;
    gap: 20px; /* Add space between buttons */
  }
  

  .header-top h4:hover {
    color: blue; /* Set text color on hover */
  }
  .header-top h3:hover {
    color: blue; /* Set text color on hover */
  }
  
  .header-button-logout {
    margin-right: 20px; /* Adjust margin as needed */
  }

  .header-button-logout button{
    border-radius: 5px;
  }
  
  .button:hover {
    background-color: #0056b3; /* Change background color on hover */
  }
  
  .header-button a {
    text-decoration: none; /* Remove underline */
    color: black; /* Set default text color */
  }

  .logo a{
    text-decoration: none; /* Remove underline */
    color: black; /* Set default text color */
  }
  
  .header-button a.active {
    color: blue; /* Set text color for active links */
  }

  .logo a.active {
    color: blue; /* Set text color for active links */
  }


  .home-container {
  display: flex;
  justify-content: space-between; 
  padding-top: 100px;
 
}

.carousel {
margin-top: 20px;

}

.carousel-item {
text-align: center;
}

.carousel-control-prev{
    margin: 7em;
}

.carousel-control-next{
    margin: 7em;
}
.carousel-caption {
color: rgb(180, 180, 11);
border-radius: 5px;
}

.carousel-item img {
max-width: 100%; 
max-height: 300px; 
margin: 0 auto;
}

/* Add these styles to your existing CSS file */
.showcase-heading {
  color: black;
}

.showcase-text {
  color: #36454F;
}

.visibility-heading {
  color: black;
}

.visibility-text {
  color: #36454F;
}

.branding-heading {
  color:black;
}

.branding-text {
  color:black;
}





.content {
    width: 70%; 
    color: white;
    margin-left: 400px;
    margin-top: 70px;
    margin-bottom: 20px; 
  }
  
  .carousel-container {
    width: 10%; 
    margin-right: 10px; 
  }
  
  /* Home.css */
.carousel-container {
    float: right;
    width: 70%; 
  }

 


  .text-box {
    float: right;
    width: 55%; /* Adjust the width as needed */
    background-color: #f0f0f0; /* Grey background color */
    padding: 20px;
    border-radius: 5px;
    margin-left: 20px; /* Add some margin between the carousel and text box */
    font-size: 25px;
    font-weight: 200;
    color: rgb(18, 17, 17);
    background-color: linear-gradient(to top, #995dc7 10%, #f6f3ff 100%);
    /* background-color: #e3cfdd; */
  }
  
  /* Home.css */
  .text-box p {
    color: #000; 
    font-size: 24px; 
    white-space: nowrap;
    overflow: hidden;
    animation: typing 3s steps(30, end), blink-caret 0.5s step-end infinite;
  }
  
  @keyframes typing {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: #000;
    }
  }

  .additional-text-box {
      position: relative;
      right: 5rem;
      top: 5rem;
  }

  .home-container-main{
    height:1000px;
  }
  
  
  
  @media (max-width: 768px) {
    .text-box {
      width: 100%;
      margin-left: 0;
    }
  
    .additional-text-box {
      width: 100%;
      margin-right: 0;
    }
  }


.custom-button {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none; /* Added to remove underline */
}

/* Additional styles for the link */
.custom-button:hover {
  background-color: rgb(103, 180, 103); /* Change color on hover if desired */
  text-decoration: none; /* Ensure no underline on hover */
  color: rgb(9, 9, 9);
}




.custom-button:focus {
  outline: none; /* Remove focus outline */
}

/* Increase font size */
.custom-button {
  font-size: 20px; /* Adjust the font size as needed */
}




/* Logout.css */
.header-top-Logout {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: white; 
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); 
  z-index: 100; 
  color: whitesmoke;
  }

  .Logout-link{
    display: flex;
    position: relative;
    left: 88rem;
  }
  .Logout-link a{
    text-decoration: none; /* Remove underline */
    color: black; /* Set default text color */
  }

  .lo

.page-container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000; /* Black text color */
  font-size: 20px;
  font-weight: 100;

}

.file-upload-container {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Add margins to the input and button for spacing */
input[type="file"] {
  margin: 10px 0;
}


.header-top-Logout h4:hover {
  color: blue; /* Set text color on hover */
}

p {
  margin: 10px 0;
}

.logout-button {
  text-align: center;
  margin-top: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.logout-button button {
  background-color: blue;
  color: white;
  padding: 20px 120px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: x-large;
  height: 58px;
}
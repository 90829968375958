.container-signup{
    display:flex;
    flex-direction: column;
    margin: 25px auto;
    width:400px;
    background: #fff;
    padding: 20px;
    border-radius: 30px;
    position: relative;
    top: 5rem;
}

.header-signup{
      display:flex;
      flex-direction: column;
      align-items: center;
      gap: 9px;
      width: 100%;
      margin-top: 10px;

}


.input{
    display: flex;
    align-items: center;
    margin: auto;
    width:100%;
    height: 60px;
    background: #eaeaea;
    border-radius: 6px ;
    margin-bottom: 10px;
}

.input input{
    height: 40px;
    width: 100%;
    background: transparent;
    border:none;
    outline:none;
    color: #797979;
    font-size: 19px;

}


.submit-container{
    display: flex;
    gap: 30px;
    justify-content: center;
    margin: 20px auto;
}

.submit{
   display:flex;
   justify-content: center;
   align-items: center;
   width: 220px;
   height: 59px; 
   color: #fff;
   background: #4c00b4;
   border-radius: 50px;
   font-size: 19px;
   font-weight: 700;
   cursor: pointer;

}

.gray{
    background-color: #EAEAEA;
    color: #676767;
}

.text{
    color: #3c009d;
    font-size: 48px;
    font-weight: 700;
}


.underline{
    width: 61px;
    height:6px;
    background: #3c009d;
    border-radius: 9px;
}

.inputs{
    margin-top: 55px;
    display:flex;
    flex-direction: column;
    gap:25px;
}

.error-message {
    color: rgb(98, 0, 255);
    text-align: left; /* Add this line */
    /* Add any other styling you want for the error message */
  }

  .signup-button {
    text-align: center;
    margin-top: 20px;
  }
  
  .signup-button button {
    background-color: #3737d4;
    color: white;
    padding: 10px 55px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: x-large;
    height: 58px;
  }

  .signup-button button:hover {
    color: lightgreen;
  }